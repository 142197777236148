@import 'syntax';

$spacingUnit: 20px;

$tablet: 700px;
$desktop: 900px;
$wide: 1100px;

$gutters: (
        'default': 20px,
        'tablet': 10%,
        'desktop': 15%,
        'wide': 20%
);

html {
    font-size: 100%;
}

@media print {
    html {
        font-size: 70%;
    }

    .sidebar {
        border-right: 1px solid #e0e0e0;
    }

    body.gridless {
        padding: 2rem 10rem;
    }

}

.shaded {
    background-color: #ebf2f8;
    padding: $spacingUnit;
}

body {
    max-width: 900px;
    margin: 2rem auto;
    font-family: 'Playfair Display', serif;
    font-weight: 400;
    line-height: 1.75;
}

a {
    color: #9a1e1e;
}

ul {
    padding-left: 1.5rem;
}

ul li {
    margin-bottom: 1rem;
}

header, section, footer {
    padding: 1rem;
}

h1, h2, h3, h4, h5, h6 {
    margin: 0 0 0.6rem 0;
    padding: 0;
    line-height: 1.3;
}

p {
    margin-top: 0;
    margin-bottom: 1rem;
}

h1 {
    font-size: 3.052rem;
    font-weight: 800;
}

h2 {
    font-size: 2.441rem;
    font-weight: 600;
}

h3 {
    font-size: 1.953rem;
    border-bottom: 1px solid #e0e0e0;
    padding-bottom: 0.6rem;
}

h4 {
    font-size: 1.563rem;
    font-weight: 600;
}

h5 {
    font-size: 1.25rem;
    font-weight: 400;
}

h6 {
    font-size: 1.2rem;
    font-weight: 400;
}

small {
    font-size: 75%;
    opacity: 0.75;
    font-weight: 400;
}

.sidebar {
    background-color: #eeeeee;
    padding: 1rem;
}

header {
    text-align: center;
    border-bottom: 1rem solid #eeeeee;
    padding-bottom: 2rem;
    margin-bottom: 1rem;

    @media screen and (min-width: 40rem) {
        display: flex;
        justify-content: space-between;
        align-items: center;
        text-align: left;
    }

    h1 {
        margin-bottom: 0;
    }

    aside {
        @media screen and (min-width: 40rem) {
            flex-shrink: 0;
            margin-left: $spacingUnit;
        }

        img {
            border-radius: 100%;
            width: 7rem;
            margin-left: 1rem;
        }
    }
}

@media screen and (min-width: 40rem), print {
    article {
        display: grid;
        grid-column-gap: 1rem;
        grid-template-columns: repeat(3, 1fr);

    }

    .grid-none {
        grid-template-columns: 1fr;
    }

    .grid-2 {
        grid-template-columns: repeat(2, 1fr);
    }

    .grid-2-1 {
        :first-child {
            grid-column-end: span 2;
        }
    }

    .grid-1-2 {
        :last-child {
            grid-column-end: span 2;
        }
    }

    .grid-col-full {
        grid-column: 1/-1;
    }

}

nav {
    $navItemGutter: $spacingUnit / 2;
    margin-top: 12px; // align with header

    @media screen and (min-width: $tablet) {
        text-align: right;
    }

    ul {
        margin: 0;
        padding-left: 0;

        @media screen and (min-width: $tablet) {
            padding-left: 1.5rem;
        }
    }

    li {
        display: inline-block;
        font-family: 'Playfair Display', serif;
        font-size: 20px;
        margin: 0;
        margin-right: $navItemGutter;

        &:last-child {
            margin-right: 0;
            &:after {
                display: none;
            }
        }

        &:after {
            content: '•';
            color: #b3bad6;
            margin-left: $navItemGutter;
        }

        a {
            color: #1b121d;
            text-decoration: none;
            &:hover {
                color: #b3bad6;
                border-bottom: 1px solid #b3bad6;
                padding-bottom: 2px;
            }
        }
    }
}

.media {
    overflow: hidden;

    .media__img {
        float: left;
        width: 120px;
        margin-right: $spacingUnit;

        @media screen and (min-width: $tablet) {
            margin-right: $spacingUnit * 2;
            width: 220px;
        }

        &.media__img--right {
            margin-right: 0;
            float: right;
            margin-left: $spacingUnit;
            @media screen and (min-width: $tablet) {
                margin-left: $spacingUnit * 2;
            }
        }
    }


    .media__body {
        overflow: hidden;
    }

}

img, video {
    width: 100%;
    height: auto;
}

iframe {
    margin: auto;
    display: block;
    margin-bottom: $spacingUnit;
}


.masthead a {
    text-decoration: none;
    color: #000000;
    &:hover {
        color: #9a1e1e;
        transition: color 0.3s ease-in;
    }
}
.minor {
    font-size: 14px;
    color: #999999;
}

.excerpt {
    hyphens: auto;
    word-wrap: break-word;
    word-break: break-all;
}

.caption {
    color: #909090;
    font-size: 14px;
    margin: $spacingUnit / 2 0 $spacingUnit;
}

footer {
    background-color: #f9f9f9;
}
